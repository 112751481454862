<template>
  <div>
    <h1 class="pb-6">Arq. Pública</h1>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="portfolios"
        :search="search"
        :loading="loadingData"
        loading-text="Cargando portfolios... Por favor espere."
        no-data-text="No hay información de portfolios, por favor cargue nuevos portfolios."
      >
        <template v-slot:item.state="{ item }">
          <v-chip :color="getStateColor(item.state)" dark>
            {{ getState(item.state) }}
          </v-chip>
        </template>

        <template v-slot:top>
          <v-toolbar flat color="dark">
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar..."
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-dialog v-model="dialog" min-width="70%">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    >Agregar Arq. Pública</v-btn
                  >
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-row align="center" justify="space-around">
                      <v-col cols="6">
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.name"
                            label="Nombre"
                            :rules="nameRules"
                            required
                          ></v-text-field>
                          <v-text-field
                            v-if="editedIndex != -1"
                            v-model="editedItem.slug"
                            label="URL Slug"
                          ></v-text-field>
                          <v-textarea
                            v-model="editedItem.description"
                            label="Descripción"
                          ></v-textarea>
<!--                           <v-select
                            label="Seleccione categoría"
                            :rules="nameRules"
                            required
                            v-model="editedItem.category"
                            item-text="name"
                            item-value="_id"
                            :items="categoryList"
                          ></v-select> -->
                        </v-col>

                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.videoURL"
                            label="URL del Video"
                          ></v-text-field>
                        </v-col>

                        <!--                         <v-col cols="12">
                          <v-file-input
                            :loading="loadingVideo"
                            v-model="videoUploaded"
                            color="deep-purple accent-4"
                            placeholder="Seleccionar video"
                            prepend-icon="mdi-paperclip"
                            outlined
                            persistent-hint
                            hint="El video debe estar en formato mp4."
                            :show-size="1000"
                            v-if="!editedItem.video.url"
                          >
                            <template v-slot:selection="{ index, text }">
                              <v-chip
                                v-if="index < 2"
                                color="deep-purple accent-4"
                                dark
                                label
                                small
                              >
                                {{ text }}
                              </v-chip>

                              <span
                                v-else-if="index === 2"
                                class="overline grey--text text--darken-3 mx-2"
                              >
                                +{{ files.length - 2 }} File(s)
                              </span>
                            </template>
                          </v-file-input>
                          <v-btn
                            color="red--text"
                            v-if="editedItem.video.url"
                            @click="deleteVideo"
                            >Eliminar video</v-btn
                          >
                        </v-col> -->

                        <v-col cols="12" v-if="editedItem.marker">
                          <v-text-field
                            v-model="editedItem.marker.position.lat"
                            label="Latitud (Coordenadas)"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" v-if="editedItem.marker">
                          <v-text-field
                            v-model="editedItem.marker.position.lng"
                            label="Longitud (Coordenadas)"
                          ></v-text-field>
                        </v-col>
                      </v-col>
                      <v-col cols="6">
                        <v-row>
                          <v-col cols="12">
                            <v-row>
                              <v-col cols="12">
                                <p>Imágenes del proyecto:</p>
                              </v-col>
                              <v-col cols="12">
                                <div class="addNew d-flex" @click="inputClick">
                                  <v-row align="center">
                                    <v-col class="text-center">
                                      <v-icon size="40">mdi-plus</v-icon>
                                      <p>Agregar imagen</p>
                                    </v-col>
                                  </v-row>
                                </div>
                                <v-file-input
                                  v-if="editedIndex == -1"
                                  multiple
                                  outlined
                                  dense
                                  v-model="imageUploaded"
                                  color="deep-purple accent-4"
                                  placeholder="Seleccionar imágenes"
                                  prepend-icon="mdi-paperclip"
                                  persistent-hint
                                  class="mb-3 inpFile"
                                  name="images"
                                ></v-file-input>
                                <v-file-input
                                  v-if="editedIndex != -1"
                                  multiple
                                  outlined
                                  dense
                                  v-model="imageEditedUploaded"
                                  color="deep-purple accent-4"
                                  placeholder="Seleccionar imágenes"
                                  prepend-icon="mdi-paperclip"
                                  persistent-hint
                                  class="mb-3 inpFile"
                                  name="images"
                                ></v-file-input>
                              </v-col>
                              <p v-show="imageRequired" class="red--text">
                                Es obligatorio subir alguna imagen.
                              </p>
                              <v-col
                                cols="12"
                                v-if="uploadedImages.length >= 1"
                              >
                                <draggable
                                  :list="uploadedImages"
                                  ghost-class="ghost"
                                  @start="dragging = true"
                                  @end="dragging = false"
                                >
                                  <div
                                    v-for="(image, index) in uploadedImages"
                                    :key="index"
                                    class="image-preview"
                                    id="imagePreview"
                                  >
                                    <v-img
                                      :src="image.url"
                                      :lazy-src="image.url"
                                      height="120px"
                                      width="auto"
                                      alt="Image Preview"
                                    >
                                      <span
                                        class="removeButton"
                                        @click="deleteSavedImages(index)"
                                        >X</span
                                      ></v-img
                                    >
                                  </div>
                                  <v-skeleton-loader
                                    v-if="uploadingImages"
                                    class="skeleton"
                                    width="200"
                                    height="130"
                                    type="image"
                                  ></v-skeleton-loader>
                                </draggable>
                              </v-col>

                              <v-col
                                v-if="editedItem.portfolioimages"
                                class="portfolioImages"
                              >
                                <draggable
                                  :list="editedItem.portfolioimages"
                                  ghost-class="ghost"
                                  @start="dragging = true"
                                  @end="dragging = false"
                                >
                                  <div
                                    v-for="(image,
                                    index) in editedItem.portfolioimages"
                                    :key="index"
                                    class="image-preview"
                                    id="imagePreview"
                                  >
                                    <v-img
                                      :src="image.url"
                                      :lazy-src="image.url"
                                      height="120px"
                                      width="auto"
                                      alt="Image Preview"
                                    >
                                      <span
                                        class="removeButton"
                                        @click="deletePortfolioImages(index)"
                                        >X</span
                                      ></v-img
                                    >
                                  </div>
                                  <v-skeleton-loader
                                    v-if="uploadingEditedArrayImages"
                                    class="skeleton"
                                    width="200"
                                    height="125"
                                    type="image"
                                  ></v-skeleton-loader>
                                </draggable>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="ma-2" @click="close">Cancelar</v-btn>
                    <v-btn class="ma-2" color="success" @click="save">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="editItem(item)" class="mr-2">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)" class="mr-2"
            >mdi-delete</v-icon
          >
          <v-icon
            small
            @click="desactivateItem(item)"
            class="mr-2"
            v-if="item.state === 1"
            >mdi-eye-off</v-icon
          >
          <v-icon small @click="activateItem(item)" v-if="item.state === 0"
            >mdi-eye</v-icon
          >
        </template></v-data-table
      >
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import draggable from 'vuedraggable'
import VueUploadMultipleImage from 'vue-upload-multiple-image'
export default {
  inject: {
    theme: {
      default: { isDark: false }
    }
  },
  components: {
    VueUploadMultipleImage,
    draggable
  },
  data: () => ({
    loadingVideoBackgroundImage: false,
    backgroundVideoImage: null,
    video: '',
    file: '',
    loadingVideo: false,
    videoUploaded: null,
    imageRequired: false,
    valid: true,
    nameRules: [(v) => !!v || 'Este campo es obligatorio.'],
    imageRules: [
      (v) => !!v || 'El archivo es requerido',
      (v) => (v && v.size > 0) || 'El archivo es requerido'
    ],
    categoryList: [],
    imageEditedUploaded: [],
    deletedImagesPublicID: [],
    uploadedImages: [],
    portfolios: [],
    portfolioImages: '',
    uploadingImages: false,
    uploadingEditedArrayImages: false,
    dragging: false,
    imageUploaded: null,
    loadingData: true,
    dialog: false,
    editedIndex: -1,
    editedItem: {
      marker: {
        position: { lat: '', lng: '' }
      },
      name: '',
      clientReview: '',
      description: '',
      portfolioimages: [],
      problem: '',
      solution: '',
      video: {
        url: null,
        public_id: ''
      },
      videoBackgroundImage: {
        url: null,
        public_id: ''
      },
      videoURL: ''
    },
    defaultItem: {
      name: '',
      clientReview: '',
      description: '',
      portfolioimages: [],
      problem: '',
      solution: '',
      video: {
        url: null,
        public_id: ''
      },
      videoBackgroundImage: {
        url: null,
        public_id: ''
      }
    },
    search: '',
    headers: [
      {
        text: 'Proyecto',
        filterable: true,
        value: `name`
      },
      {
        text: 'Slug',
        value: `slug`
      },
      { text: 'Estado', filterable: true, value: 'state' },
      { text: 'Acciones', value: 'actions' }
    ]
  }),
  methods: {
    deleteVideo() {
      let me = this
      let portfolioId = this.editedItem._id
      confirm('Estás a punto de eliminar el video ¿Continuar?') &&
        axios
          .delete('arq-publica/deleteVideo', {
            params: {
              id: portfolioId,
              public_id: this.editedItem.video.public_id
            },
            headers: {
              token: me.$store.state.user.token
            }
          })
          .then(function() {
            me.editedItem.video = {
              url: '',
              public_id: ''
            }
            me.initialize()
            me.$store.dispatch('snackbar/setSnackbar', {
              text: `Se eliminó correctamente el video.`
            })
          })
          .catch(function(error) {
            console.log(error)
          })
    },
    deleteVideoBackgroundImage() {
      let me = this
      let portfolioId = this.editedItem._id
      confirm(
        'Estás a punto de eliminar la imagen de fondo del video ¿Continuar?'
      ) &&
        axios
          .delete('arq-publica/deleteVideoBackgroundImage', {
            params: {
              id: portfolioId,
              public_id: this.editedItem.videoBackgroundImage.public_id
            },
            headers: {
              token: me.$store.state.user.token
            }
          })
          .then(function() {
            me.editedItem.videoBackgroundImage = {
              url: '',
              public_id: ''
            }
            me.initialize()
            me.$store.dispatch('snackbar/setSnackbar', {
              text: `Se eliminó correctamente la imagen de fondo del video.`
            })
          })
          .catch(function(error) {
            console.log(error)
          })
    },
    validate() {
      return this.$refs.form.validate()
    },
/*     getCategories() {
      let me = this
      axios
        .get('portfoliocategories')
        .then(function(response) {
          me.categoryList = response.data
        })
        .catch(function(error) {
          console.log(error)
        })
    }, */
    inputClick() {
      document
        .getElementsByClassName('inpFile')[0]
        .querySelector('button')
        .click()
    },
    getStateColor(state) {
      if (state === 1) return 'green'
      else return 'red'
    },
    getState(state) {
      if (state === 1) return 'Activo'
      else return 'Desactivado'
    },

    desactivateItem(item) {
      let me = this
      let header = { token: this.$store.state.user.token }
      let configuration = { headers: header }
      axios
        .put(
          'arq-publica/desactivate',
          {
            _id: item._id
          },
          configuration
        )
        .then(function() {
          me.initialize()
          me.$store.dispatch('snackbar/setSnackbar', {
            text: `Se desactivó correctamente el portfolio.`
          })
        })
        .catch(function(error) {
          console.log(error)
        })
    },

    activateItem(item) {
      let me = this
      let header = { token: this.$store.state.user.token }
      let configuration = { headers: header }
      axios
        .put(
          'arq-publica/activate',
          {
            _id: item._id
          },
          configuration
        )
        .then(function() {
          me.initialize()
          me.$store.dispatch('snackbar/setSnackbar', {
            text: `Se activó correctamente el portfolio.`
          })
        })
        .catch(function(error) {
          console.log(error)
        })
    },

    deleteSavedImages(index) {
      let me = this
      this.uploadedImages.map(function(i) {
        if (i.index == index) {
          me.deletedImagesPublicID.push(i.public_id)
        }
      })
      this.uploadedImages.splice(index, 1)
    },
    deletePortfolioImages(index) {
      let me = this
      this.editedItem.portfolioimages.map(function(i) {
        if (i.index == index) {
          me.deletedImagesPublicID.push(i.public_id)
        }
      })
      this.editedItem.portfolioimages.splice(index, 1)
    },

    editItem(item) {
      this.editedIndex = this.portfolios.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      let me = this
      let portfolioId = item._id
      confirm('Estás a punto de eliminar el portfolio ¿Continuar?') &&
        axios
          .delete('arq-publica', {
            params: { id: portfolioId },
            headers: {
              token: me.$store.state.user.token
            }
          })
          .then(function() {
            me.initialize()
            me.$store.dispatch('snackbar/setSnackbar', {
              text: `Se eliminó correctamente el portfolio.`
            })
          })
          .catch(function(error) {
            console.log(error)
          })
    },

    close() {
      this.initialize()
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (
        !this.editedItem.portfolioimages.length >= 1 &&
        !this.uploadedImages.length >= 1
      ) {
        this.valid = false
        this.imageRequired = true
      } else {
        this.valid = true
        this.imageRequired = false
      }
      if (this.validate() && this.valid) {
        let me = this
        let header = { token: this.$store.state.user.token }
        let configuration = { headers: header }

        if (this.editedIndex > -1) {
          const uploadedImagesOrdered = []

          me.$store.dispatch('loading/setLoadingOverlay')

          if (me.editedItem.portfolioimages.length >= 1) {
            me.editedItem.portfolioimages.map(function(i, index) {
              uploadedImagesOrdered.push({
                public_id: i.public_id,
                url: i.url,
                index
              })
            })
          }

          me.$store.dispatch('loading/setLoadingOverlay')

          const marker = {
            position: {
              lat: this.editedItem.marker.position.lat,
              lng: this.editedItem.marker.position.lng
            },
            infoText: 'Hola'
          }

          axios
            .put(
              'arq-publica',
              {
                _id: this.editedItem._id,
                name: this.editedItem.name,
                slug: this.editedItem.slug,
                description: this.editedItem.description || '',
                portfolioimages: JSON.stringify(uploadedImagesOrdered) || '',
                deletedImagesPublicID: this.deletedImagesPublicID || '',
                videoURL: this.editedItem.videoURL || '',
                marker: marker || ''
              },
              configuration
            )
            .then(function() {
              me.initialize()
              me.$store.dispatch('loading/removeLoadingOverlay')
              me.$store.dispatch('snackbar/setSnackbar', {
                text: 'Se actualizó correctamente el Portfolio.'
              })
            })
            .catch(function(error) {
              console.log(error)
              me.$store.dispatch('loading/removeLoadingOverlay')
              me.$store.dispatch('snackbar/setSnackbar', {
                text:
                  'Hubo un error al actualizar el Portfolio, por favor actualice la página e intente nuevamente.',
                color: 'error'
              })
            })
        } else {
          let me = this
          const uploadedImagesOrdered = []

          me.$store.dispatch('loading/setLoadingOverlay')

          if (me.uploadedImages.length >= 1) {
            me.uploadedImages.map(function(i, index) {
              uploadedImagesOrdered.push({
                public_id: i.public_id,
                url: i.url,
                index
              })
            })
          }

          const marker = {
            position: {
              lat: this.editedItem.marker.position.lat,
              lng: this.editedItem.marker.position.lng
            },
            infoText: 'Hola'
          }

          axios
            .post(
              'arq-publica',
              {
                name: this.editedItem.name,
                description: this.editedItem.description || '',
                portfolioimages: JSON.stringify(uploadedImagesOrdered) || '',
                deletedImagesPublicID: this.deletedImagesPublicID || '',
                videoURL: this.editedItem.videoURL || '',
                marker: marker
              },
              configuration
            )
            .then(function(response) {
              me.initialize()
              me.$store.dispatch('loading/removeLoadingOverlay')
              me.$store.dispatch('snackbar/setSnackbar', {
                text: 'Se agregó correctamente el Portfolio.'
              })
            })
            .catch(function(error) {
              console.log(error)
              me.$store.dispatch('loading/removeLoadingOverlay')
              me.$store.dispatch('snackbar/setSnackbar', {
                text:
                  'Hubo un error al agregar el Portfolio, por favor actualice la página e intente nuevamente.',
                color: 'error'
              })
            })
        }
        this.cleanForm()
        this.close()
      }
    },
    cleanForm() {
      this.editedItem.name = ''
      this.editedItem.description = ''
      this.editedItem.problem = ''
      this.editedItem.solution = ''
      this.editedItem.link = ''
      this.editedItem.video = ''
      this.editedItem.clientReview = ''
      this.uploadedImages = ''
      this.deletedImagesPublicID = []
      this.file = ''
      this.loadingVideo = false
      this.video = ''
    },
    initialize() {
      let me = this
      axios
        .get('arq-publica')
        .then(function(response) {
          me.portfolios = response.data
          console.log(me.portfolios)
          me.loadingData = false
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    async createVideoSlider() {
      let me = this
      if (this.file === '') {
        return
      }
      this.loadingVideo = true

      let formData = new FormData()

      formData.append('video', this.file)

      axios
        .post('arq-publica/uploadVideo', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: me.$store.state.user.token
          }
        })
        .then(function(response) {
          me.loadingVideo = false
          me.editedItem.video = response.data.video
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    async uploadVideoBackgroundImage() {
      let me = this

      if (this.file === '') {
        return
      }
      this.loadingVideoBackgroundImage = true

      let formData = new FormData()

      formData.append('image', this.file)

      axios
        .post('arq-publica/uploadVideoBackgroundImage', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: me.$store.state.user.token
          }
        })
        .then(function(response) {
          me.loadingVideoBackgroundImage = false
          me.editedItem.videoBackgroundImage = {
            public_id: response.data.public_id,
            url: response.data.url
          }
        })
        .catch(function(error) {
          console.log(error)
        })
    }
  },
  watch: {
    backgroundVideoImage: function() {
      this.file = event.target.files[0]
      this.uploadVideoBackgroundImage()
    },
    videoUploaded: function() {
      this.file = event.target.files[0]
      this.createVideoSlider()
    },
    imageEditedUploaded: function() {
      let me = this

      let formData = new FormData()
      this.portfolioImages = Array.from(event.target.files)
      this.portfolioImages.map(function(file) {
        formData.append('images', file)
      })

      me.uploadingEditedArrayImages = true

      axios
        .post('arq-publica/uploadimage', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: me.$store.state.user.token
          }
        })
        .then(function(response) {
          const newImage = [
            {
              public_id: response.data[0].public_id,
              url: response.data[0].url,
              index: me.editedItem.portfolioimages.length
            }
          ]

          me.editedItem.portfolioimages = [
            ...me.editedItem.portfolioimages,
            ...newImage
          ]
          me.uploadingEditedArrayImages = false
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    imageUploaded: function() {
      let me = this

      let formData = new FormData()
      this.portfolioImages = Array.from(event.target.files)
      this.portfolioImages.map(function(file) {
        formData.append('images', file)
      })

      me.uploadingImages = true

      axios
        .post('arq-publica/uploadimage', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: me.$store.state.user.token
          }
        })
        .then(function(response) {
          me.uploadedImages = [...me.uploadedImages, ...response.data]
          me.uploadingImages = false
        })
        .catch(function(error) {
          console.log(error)
        })
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo portfolio' : 'Editar portfolio'
    }
  },
  created() {
    this.initialize()
/*     this.getCategories() */
  }
}
</script>

<style scoped lang="scss">
.addNew {
  margin-bottom: 10px;
  border-style: dashed !important;
  width: 200px;
  height: 120px;
  cursor: pointer;
  display: inline-flex !important;
  float: left;
}
.removeButton {
  color: red;
  position: absolute;
  top: 10px;
  left: 15px;
  color: 'red';
  font-size: 25px;
  cursor: pointer;
}
.logoImg {
  margin-bottom: 20px;
}
.image-preview {
  display: inline-grid !important;
  height: 120px;
  max-width: 200px;
  border: 3px solid #fff;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #ccc;
}
.image_preview__image {
  display: block;
  width: 100%;
}
.inpFile {
  display: none;
}
.skeleton {
  display: inline-grid !important;
  border: 3px solid #fff;
  margin-bottom: 20px;
}

.portfolioImages div {
  display: flex;
}
</style>
