<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h1>{{ greet() }}</h1>
      </v-col>
      <v-col cols="12">
        <p>Bienvenido/a al Panel de Administración de <b>Constructora1</b></p>
      </v-col>
      <v-col cols="12">
        <p>Ante cualquier consulta:</p>
        <p class="mt-5">
          <a href="mailto:hola@damianguilisasti.com.ar">
            <i class="fas fa-envelope"></i> hola@damianguilisasti.com.ar
          </a>
        </p>
        <p class="mt-3">
          <a href="tel:+5493416573141">
            <i class="fas fa-phone"></i> +54 9 341 657 3141
          </a>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: true,
    loaded: false,
    theme: {
      default: { isDark: false }
    }
  }),
  methods: {
    greet() {
      const date = new Date()

      const currentTime = date.getHours()

      let greet

      if (currentTime < 12) {
        greet = 'Buenos días'
      } else if (currentTime < 18) {
        greet = 'Buenas tardes'
      } else {
        greet = 'Buenas noches'
      }

      return greet + ' ' + this.$store.state.user.name
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/variables.scss';
</style>
