<template>
  <!--   class="mx-auto"  -->
  <v-container>
    <h1>Alquiler de Máquinas</h1>
<!--     <v-row>
      <v-col cols="12">
        <v-text-field
          label="Título de la sección"
          v-model="title"
          outlined
          persistent-hint
          hint="Dejar en vacío para desactivarlo - Esta información se mostrará en toda la página."
        ></v-text-field>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col cols="12">
        <v-text-field
          label="Descripción del servicio"
          v-model="description"
          outlined
          persistent-hint
          hint="Descripción del servicio de Alquiler de Máquinas."
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn dark color="green" v-if="newOrder" @click="saveNewOrder"
          >Guardar nuevo orden</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <draggable
        style="display: contents"
        :list="Imagens"
        ghost-class="ghost"
        :move="checkMove"
        @start="dragging = true"
        @end="dragging = false"
      >
        <v-col
          class="moveCursor"
          cols="auto"
          v-for="Imagen in Imagens"
          :key="Imagen._id"
        >
          <v-img
            :src="Imagen.logoImg.url"
            :lazy-src="Imagen.logoImg.url"
            class="ImagenImg"
            height="200px"
            width="200px"
            ><span class="removeButton" @click="deleteClientImagen(Imagen)"
              >X</span
            ></v-img
          >
        </v-col>
      </draggable>
      <v-col cols="auto">
        <div class="addNew d-flex" @click="dialog = true">
          <v-row align="center">
            <v-col class="text-center">
              <v-icon size="40">mdi-plus</v-icon>
              <p>Agregar Imagen</p>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Agregar Imagen</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-file-input
                    class="mt-5"
                    label="Imagen de la Máquina"
                    outlined
                    required
                    :rules="imageRules"
                    dense
                    :loading="loadingImagen"
                    v-model="imageUploaded"
                    color="deep-purple accent-4"
                    placeholder="Seleccionar imagen"
                    prepend-icon="mdi-paperclip"
                    persistent-hint
                    hint="El Imagen debe ser de formato PNG o JPG."
                    :show-size="1000"
                  ></v-file-input>
                  <img
                    height="100px"
                    :src="editedItem.sliderImg.url"
                    v-if="editedItem.sliderImg.url"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    v-if="editedItem.sliderImg.url"
                    color="red--text"
                    @click="deleteSliderImg"
                    >Eliminar Imagen</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cleanForm()">
              Cancelar
            </v-btn>
            <v-btn
              color="green darken-1"
              :disabled="!valid"
              text
              @click="uploadImagen()"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-row>
      <v-col>
        <v-btn color="success" class="mr-3 mt-3" @click="updateMaquinasData"
          >Guardar cambios</v-btn
        >
        <v-btn
          v-if="companyImg"
          color="red--text"
          class="mr-3 mt-3"
          @click="deleteCompanyImg"
          >Eliminar imagen</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import draggable from 'vuedraggable'
export default {
  components: {
    draggable
  },
  data: () => ({
    valid: true,
    imageRules: [
      (v) => !!v || 'El archivo es requerido',
      (v) => (v && v.size > 0) || 'El archivo es requerido'
    ],
    newSliderImg: false,
    dialog: false,
    Imagens: [],
    imageFile: '',
    imageUploaded: null,
    imageURL: '',
    loadingImagen: false,
    title: '',
    description: '',
    editedIndex: -1,
    editedItem: {
      title: '',
      subtitle: '',
      sliderImg: ''
    },
    newOrder: false,
    darkImagen: false
  }),

  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    checkMove: function(e) {
      this.newOrder = true
    },
    updateMaquinasData() {
      let me = this
      let header = { token: this.$store.state.user.token }
      let configuration = { headers: header }
      console.log(this.maquinas)
      axios
        .put(
          'maquinas',
          {
            _id: this.maquinas[0]._id,
            title: this.title,
            description: this.description
          },
          configuration
        )
        .then(function(response) {
          me.$store.dispatch('snackbar/setSnackbar', {
            text: 'Se actualizó correctamente.'
          })
          me.getMaquinas()
        })
        .catch(function(error) {
          console.log(error)
          me.$store.dispatch('snackbar/setSnackbar', {
            text:
              'Hubo un error al actualizar el orden de los Imagens, por favor actualice la página e intente nuevamente.',
            color: 'error'
          })
        })
    },
    saveNewOrder() {
      let me = this
      let header = { token: this.$store.state.user.token }
      let configuration = { headers: header }
      axios
        .post(
          'maquinasImagenes/updateIndex',
          { logos: this.Imagens }, //aca
          configuration
        )
        .then(function(response) {
          me.$store.dispatch('snackbar/setSnackbar', {
            text: 'Se actualizó correctamente el orden de los Imagens.'
          })
          me.getImagens()
        })
        .catch(function(error) {
          console.log(error)
          me.$store.dispatch('snackbar/setSnackbar', {
            text:
              'Hubo un error al actualizar el orden de los Imagens, por favor actualice la página e intente nuevamente.',
            color: 'error'
          })
        })
    },
    deleteClientImagen(Imagen) {
      let me = this
      let ImagenId = Imagen._id
      confirm('Estás a punto de eliminar el Imagen, ¿Continuar?') &&
        axios
          .delete('maquinasImagenes', {
            params: { id: ImagenId },
            headers: {
              token: me.$store.state.user.token
            }
          })
          .then(function(response) {
            me.getImagens()
            me.$store.dispatch('snackbar/setSnackbar', {
              text: `Se eliminó correctamente el Imagen.`
            })
          })
          .catch(function(error) {
            console.log(error)
            me.$store.dispatch('snackbar/setSnackbar', {
              text: `No se pudo eliminar el Imagen, por favor actualice e intente nuevamente.`,
              color: 'red'
            })
          })
    },
    getMaquinas() {
      let me = this
      axios
        .get('maquinas')
        .then(function(response) {
          me.maquinas = response.data
          me.title = response.data[0].title
          me.description = response.data[0].description
          console.log(me.maquinas)
        })
        .catch(function(error) {
          console.log(error)
        })
    },

    getImagens() {
      let me = this
      axios
        .get('maquinasImagenes')
        .then(function(response) {
          me.Imagens = response.data
          console.log(me.Imagens)
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    uploadImagen() {
      if (this.validate()) {
        if (this.imageFile === '') {
          return
        }
        this.loadingImagen = true
        let me = this
        let formData = new FormData()

        formData.append('image', this.imageFile)

        axios
          .post('maquinas', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              token: me.$store.state.user.token
            }
          })
          .then(function() {
            me.loadingImagen = false
            me.$store.dispatch('snackbar/setSnackbar', {
              text: 'Se subió correctamente el Imagen.'
            })
            me.cleanForm()
            me.getImagens()
          })
          .catch(function(error) {
            console.log(error)
            me.$store.dispatch('snackbar/setSnackbar', {
              text:
                'Hubo un error al subir el Imagen, por favor actualice la página e intente nuevamente.',
              color: 'error'
            })
          })
        this.dialog = false
      }
    },
    cleanForm() {
      ;(this.imageFile = ''),
        (this.imageUploaded = null),
        (this.loadingImagen = false),
        (this.editedItem.title = ''),
        (this.editedItem.subtitle = '')
      this.dialog = false
      this.editedIndex = -1
    }
  },
  watch: {
    imageUploaded: function() {
      this.imageFile = event.target.files[0]
    }
  },
  created() {
    this.getMaquinas()
    this.getImagens()
  }
}
</script>

<style scoped lang="scss">
.addNew {
  border-style: dashed !important;
  width: 200px;
  height: 200px;
  cursor: pointer;
  display: inline-flex !important;
  float: left;
}
.sliderCard {
  display: inline-block;
  float: left;
  height: 330px;
}
.removeButton {
  position: absolute;
  top: 10px;
  left: 15px;
  color: 'red';
  font-size: 25px;
}
.ImagenImg .v-image__image .v-image__image--cover :hover {
  opacity: 0.5;
}
.moveCursor:hover {
  cursor: move;
}
@media (min-width: 1264px) {
}
.container {
  max-width: none !important;
}

@media (min-width: 960px) {
}
.container {
  max-width: none !important;
}
</style>
