<template>
  <v-container fluid>
    <h1>Fuente principal</h1>
    <v-row>
      <v-col>
        <v-text-field
          label="Fuente principal"
          v-model="settings[0].fontFamily"
          prepend-icon="mdi-format-font"
          hint="Fuente principal de la página web"
          persistent-hint
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn color="success" class="mr-3" @click="updateFont"
          >Guardar cambios</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    async updateFont() {
      try {
        const result = await this.$store.dispatch(
          "settings/updateFont",
          {
            _id: this.$store.state.settings.settings[0]._id,
            fontFamily: this.settings[0].fontFamily,
          },
          { root: true }
        );
        if (result.status === 204) {
          this.$store.dispatch("snackbar/setSnackbar", {
            text: `Se modificó correctamente la fuente.`,
          });
        }
      } catch (error) {
        this.$store.dispatch("snackbar/setSnackbar", {
          text: `No se pudo modificar la fuente.`,
          color: "red",
        });
      }
    },
  },
  computed: {
    ...mapGetters("settings", ["settings"]),
  },
};
</script>
